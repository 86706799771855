<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">微信号:</span>
                <input type="text" v-model="data.weixin_no" class="form-control col" :disabled="isEdit">
                <span class="form-label">微信类型:</span>
                <Selection :items="{ 1: '个人微信', 2: '企业微信', 3: '企微获客' }" placeholder="请选择微信类型" v-model="data.weixin_type"
                    style-class="col"></Selection>
            </div>
            <div class="form-item" v-if="data.weixin_type == 1 || data.weixin_type == 2">
                <span class="form-label">二维码:</span>
                <input type="file" ref="file" class="form-control col" @change="handleFileChanged">
            </div>
            <div class="form-item" v-if="data.weixin_type == 3">
                <span class="form-label">企微获客链接:</span>
                <textarea v-model.trim="data.wxwork_url" class="form-control col2"></textarea>
            </div>
            <div class="form-item">
                <span class="form-label">关联微信组:</span>
                <ul class="children col">
                    <li v-for="(item, index) in weixinGroups" :key="index" class="form-check"><label><input
                                type="checkbox" v-model="data.weixin_group_ids" :value="index">{{ item }}</label></li>
                </ul>
                <span class="form-label">归属客服:</span>
                <Selection :items="members" placeholder="请选择微信客服" v-model="data.member_id" style-class="col">
                </Selection>
            </div>
            <div class="form-item">
                <span class="form-label">是否开启:</span>
                <div class="form-switch col"><input type="checkbox" v-model="data.is_enabled"></div>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'WeixinGroupForm',
    components: {
        Selection
    },
    props: {
        value: Object,
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            weixinGroups: [],
            members: [],
            data: {
                weixin_no: '',
                weixin_group_ids: []
            }
        }
    },
    mounted() {
        this.fetchWeixinGroups()
        this.fetchMembers()
    },
    methods: {
        handleSubmit() {
            if (!this.data.weixin_no) {
                alert('微信号不能为空！')
                return false
            }
            if (this.data.weixin_group_ids.length == 0) {
                alert('请选择微信组！')
                return false
            }
            this.$emit('handleSubmit', this.data, this.$refs.file)
            return false
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups', this.query).then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchMembers() {
            this.$api.get('members', this.query).then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleFileChanged(event) {
            let filePath = event.target.value
            let fileName = filePath.substring(filePath.lastIndexOf('\\') + 1, filePath.lastIndexOf('.'));
            if (!this.data.weixin_no) {
                this.data.weixin_no = fileName
            }
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>