<template>
    <div class="detail-wrap">
        <div class="page-header">
            <h3>个人信息</h3>
        </div>
        <MemberForm v-model="data" @handleSubmit="handleSubmit" :edit="true"></MemberForm>
    </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex'

import MemberForm from '../components/MemberForm.vue'

export default {
    name: 'Profile',
    components: {
        MemberForm
    },
    computed: {
        ...mapState(['userInfo'])
    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading', 'setUserInfo']),
        fetchData() {
            this.startLoading()
            this.$api.post('member/detail', { id: this.userInfo.id }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        toList() {
            this.$router.push('/members')
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('member/update', data).then(res => {
                if (res.data.success) {
                    this.setUserInfo({ ...this.userInfo, ...{ name: data.name, username: data.username } })
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>