<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">归属账户:</span>
                <Selection placeholder="请选择归属账户" v-model="data.ad_account_id" :readonly="true" remote="ad_accounts"
                    style-class="col">
                </Selection>
            </div>
            <div class="form-item">
                <span class="form-label">域名:</span>
                <input type="text" v-model.trim="data.hostname" class="form-control col">
                <span class="form-label">后缀:</span>
                <input type="text" v-model.trim="data.code" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">Token:</span>
                <textarea v-model.trim="data.ocpc_token" class="form-control col2"></textarea>
            </div>
            <div class="form-item">
                <span class="form-label">Param1:</span>
                <input type="text" v-model.trim="data.param1" class="form-control col">
                <span class="form-label">Param2:</span>
                <input type="text" v-model.trim="data.param2" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">Param3:</span>
                <input type="text" v-model.trim="data.param3" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">提交时推送:</span>
                <input type="text" v-model="data.ocpc_form_type" class="form-control col">
                <span class="form-label">购买时推送:</span>
                <input type="text" v-model="data.ocpc_paid_type" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">售卖价格:</span>
                <input type="text" v-model.trim="data.money" class="form-control col">
                <span class="form-label">红包金额:</span>
                <input type="text" v-model.trim="data.hongbao_amounts" class="form-control col">
            </div>
            <div class="form-item" v-if="userInfo.role_permissions.includes('ADMIN')">
                <span class="form-label">统计代码:</span>
                <textarea v-model.trim="data.stat_code" class="form-control col2"></textarea>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'
import { mapState } from 'vuex'

export default {
    name: 'AdTagForm',
    components: {
        Selection
    },
    computed: {
        ...mapState(['userInfo'])
    },
    props: {
        value: Object
    },
    data() {
        return {
            data: this.value
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>