<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>{{ $route.params.domain }} 添加解析</h3>
            <div class="actions">
                <span class="btn btn-light" @click="toList">返回解析列表</span>
            </div>
        </div>
        <div class="form">
            <form @submit.prevent="handleSubmit">
                <div class="form-item">
                    <span class="form-label">域名:</span>
                    <input type="text" v-model.trim="data.value" class="form-control">
                </div>
                <div class="footing">
                    <button type="submit" class="btn btn-primary">保存</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

export default {
    name: 'WeixinGroupNew',
    data() {
        return {
            data: {
                host_index: this.$route.params.host_index,
                site_id: this.$route.params.site_id,
                domain: this.$route.params.domain,
                value: ''
            }
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        toList() {
            this.$router.push('../domains')
        },
        handleSubmit(data) {
            let regex = new RegExp("^[a-z0-9][a-z0-9\\-]+$", "i");
            if (!regex.test(data.value)) {
                alert('请正确填写域名!');
                return
            }
            this.startLoading()
            this.$api.post('host/domain_create', this.data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                    this.toList()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>