<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>账户编辑</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AdAccountForm v-model="data" @handleSubmit="handleSubmit"></AdAccountForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import AdAccountForm from '../../components/AdAccountForm.vue'

export default {
    name: 'WeixinGroupEdit',
    components: {
        AdAccountForm
    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('ad_account/detail', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    let tmpData = res.data.data
                    if (tmpData.balance_ad_account_ids) {
                        tmpData.balance_ad_account_ids = tmpData.balance_ad_account_ids.split(',')
                    } else {
                        tmpData.balance_ad_account_ids = ['']
                    }
                    if (tmpData.weixin_group_ids) {
                        tmpData.weixin_group_ids = tmpData.weixin_group_ids.split(',')
                    } else {
                        tmpData.weixin_group_ids = []
                    }
                    console.info(tmpData)
                    this.data = tmpData
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        toList() {
            this.$router.push('/ad_accounts')
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('ad_account/update', data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>