<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>{{ $route.params.oid }}</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <div class="form">
            <form @submit.prevent="handleSubmit">
                <div class="form-item">
                    <span class="form-label">订单编号:</span>
                    <p class="text col">{{ $route.params.oid }}</p>
                </div>
                <div class="form-item" v-if="userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('WEIXIN_LEADER')">
                    <span class="form-label">审核状态:</span>
                    <Selection :items="['待审核', '通过', '拒绝']" placeholder="请选择审核状态" v-model="data.status" style-class="col">
                    </Selection>
                </div>
                <div class="form-item">
                    <span class="form-label">八字详解:</span>
                    <textarea v-model="data.data.data_extend.bzxj" class="form-control col2" rows="8"></textarea>
                </div>
                <div class="form-item">
                    <span class="form-label">五行综述:</span>
                    <textarea v-model="data.data.data_extend.wxzs" class="form-control col2" rows="8"></textarea>
                </div>
                <div class="form-item">
                    <span class="form-label">感情/姻缘:</span>
                    <textarea v-model="data.data.data_extend.rglm.aqfx" class="form-control col2" rows="10"></textarea>
                </div>
                <div class="form-item">
                    <span class="form-label">财运:</span>
                    <textarea v-model="data.data.data_extend.rglm.syfx" class="form-control col2" rows="10"></textarea>
                </div>
                <div class="form-item">
                    <span class="form-label">事业:</span>
                    <textarea v-model="data.data.data_extend.shiye" class="form-control col2" rows="10"></textarea>
                </div>
                <div class="footing">
                    <button type="submit" class="btn btn-primary">保存</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex'

import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderModifyBzjp',
    components: {
        Selection
    },
    computed: {
        ...mapState(['userInfo'])
    },
    data() {
        return {
            data: {},
            logs: [],
            total_count: 0,
            page: 1,
            page_size: 10
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('order/modify_bzjp', { oid: this.$route.params.oid }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleSubmit() {
            this.startLoading()
            this.$api.post('order/modify_update', this.data).then(res => {
                alert(res.data.message)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>