<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>支付组编辑</h3>
            <div class="actions">
                <span class="btn btn-light" @click="toList">返回列表</span>
            </div>
        </div>
        <PayGroupForm v-model="data" @handleSubmit="handleSubmit"></PayGroupForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import PayGroupForm from '../../components/PayGroupForm.vue'

export default {
    name: 'WeixinGroupEdit',
    components: {
        PayGroupForm
    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('pay_group/detail', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    let tmpData = res.data.data
                    if (tmpData.member_ids) {
                        tmpData.member_ids = tmpData.member_ids.split(',')
                    } else {
                        tmpData.member_ids = []
                    }
                    this.data = tmpData
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        toList() {
            this.$router.push('/pay_groups')
        },
        handleSubmit() {
            this.startLoading()
            this.$api.post('pay_group/update', this.data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>