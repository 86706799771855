<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>网站列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加网站配置</span>
                    </div>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="160">域名</th>
                            <th>公司名</th>
                            <th width="80">售卖价格</th>
                            <th width="140">创建时间</th>
                            <th width="120">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.hostname }}</td>
                            <td>{{ item.company_name }}</td>
                            <td>{{ item.money }}</td>
                            <td>{{ item.created_at }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemEdit(item.id)">编辑</span> <span
                                    class="btn btn-light btn-s" @click="handleItemDelete(item)">删除</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.query = this.getDefaultQuery()
        this.fetchList()
    },
    data() {
        return {
            query: {
                weixin_group_id: null
            },
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                page: 1,
                page_size: 10
            }
            return query
        },
        onClear() {
            this.query = {
                weixin_group_id: null
            }
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('web/list', { ...this.query, ...{ page: page, page_size: this.page_size } }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleItemEdit(id) {
            this.$router.push('/webs/' + id + '/edit')
        },
        handleNew() {
            this.$router.push('/webs/new')
        },
        handleItemDelete(item) {
            if (confirm('确定要删除用户【' + item.hostname + '】吗？')) {
                this.$api.post('web/delete', { id: item.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
