<template>
    <div class="order-list">
        <div class="page-header">
            <h3>账户报表</h3>
        </div>
        <form class="search" @submit.prevent="onSearch">
            <div class="search-item">
                <label>账户状态:</label>
                <Selection :items="['未开启', '已开启']" placeholder="全部" v-model="query.is_enabled" width="120">
                </Selection>
            </div>
            <div class="search-item">
                <label>推广账户:</label>
                <Selection placeholder="全部" v-model="query.id" :edit="true" remote="ad_accounts"></Selection>
            </div>
            <div class="search-item">
                <label>开始时间:</label>
                <input type="date" class="form-control" v-model="query.start_date">
            </div>
            <div class="search-item">
                <label>结束时间:</label>
                <input type="date" class="form-control" v-model="query.end_date">
            </div>
            <div class="search-item buttons">
                <button type="submit" class="btn btn-primary">查询</button>
                <span class="divider"></span>
                <button type="button" class="btn btn-light" @click="onClear">清空</button>
            </div>
        </form>
        <div class="table">
            <table v-if="items.length > 0">
                <tr>
                    <th>账户名</th>
                    <th width="70">当前余额</th>
                    <th width="70">展现</th>
                    <th width="70">点击</th>
                    <th width="70">消费</th>
                    <th width="70">现金消费</th>
                    <th width="70">订单数</th>
                    <th width="70">付费数</th>
                    <th width="70">付费率</th>
                    <th width="70">付费总额</th>
                    <th width="84">平均订单成本</th>
                    <th width="70">收益</th>
                </tr>
                <tr v-for=" (item, i) in items" :key="i">
                    <td>{{ item.username }}</td>
                    <td>{{ item.balance }}</td>
                    <td>{{ item.total_show }}</td>
                    <td>{{ item.total_click }}</td>
                    <td>{{ item.total_cost }}</td>
                    <td>{{ item.total_cash }}</td>
                    <td>{{ item.total_orders }}</td>
                    <td>{{ item.paid_orders }}</td>
                    <td>{{ paidRate(item.paid_orders, item.total_orders) }}</td>
                    <td>{{ item.paid_money }}</td>
                    <td>{{ paidAvg(item.total_cash, item.paid_money, item.paid_orders) }}</td>
                    <td :class="'pct pct-' + income_center_rate + '-' + item.income_rate"><span class="text">{{
            item.income }}</span></td>
                </tr>
                <tr>
                    <td class="text-right">合计:</td>
                    <td></td>
                    <td>{{ sum_total_show }}</td>
                    <td>{{ sum_total_click }}</td>
                    <td>{{ sum_total_cost }}</td>
                    <td>{{ sum_total_cash }}</td>
                    <td>{{ sum_total_orders }}</td>
                    <td>{{ sum_paid_orders }}</td>
                    <td>{{ paidRate(sum_paid_orders, sum_total_orders) }}</td>
                    <td>{{ sum_paid_money }}</td>
                    <td>{{ paidAvg(sum_total_cash, sum_paid_money, sum_paid_orders) }}</td>
                    <td>{{ sum_income }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderList',
    components: {
        Selection
    },
    mounted() {
        this.query = this.getDefaultQuery()
        this.fetchList()
    },
    data() {
        return {
            query: {
                is_enabled: 1,
                id: null,
                start_date: '',
                end_date: ''
            },
            items: [],
            sum_total_show: 0,
            sum_total_click: 0,
            sum_total_cost: 0,
            sum_total_cash: 0,
            sum_total_orders: 0,
            sum_paid_orders: 0,
            sum_paid_money: 0,
            sum_income: 0,
            income_center_rate: 0
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                is_enabled: 1,
                id: null
            }
            const now = new Date()
            query.end_date = query.start_date = this.formatDateYmd(now)
            return query
        },
        onClear() {
            this.query = this.getDefaultQuery()
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList() {
            this.sum_total_show = 0;
            this.sum_total_click = 0;
            this.sum_total_cost = 0;
            this.sum_total_cash = 0;
            this.sum_total_orders = 0;
            this.sum_paid_orders = 0;
            this.sum_paid_money = 0;
            this.sum_income = 0;
            this.income_center_rate = 0;
            this.startLoading()
            this.$api.post('ad_report/account_report', this.query).then(res => {
                if (res.data.success) {
                    this.items = res.data.data
                    let max_income = 0;
                    let min_income = 0;
                    for (let item of this.items) {
                        this.sum_total_show += parseInt(item.total_show)
                        this.sum_total_click += parseInt(item.total_click)
                        this.sum_total_cost += parseFloat(item.total_cost)
                        this.sum_total_cash += parseFloat(item.total_cash)
                        this.sum_total_orders += parseInt(item.total_orders)
                        this.sum_paid_orders += parseInt(item.paid_orders)
                        this.sum_paid_money += parseFloat(item.paid_money)
                        item.balance = parseFloat(item.balance).toFixed(2)
                        item.total_cost = parseFloat(item.total_cost).toFixed(2)
                        item.total_cash = parseFloat(item.total_cash).toFixed(2)
                        item.paid_money = parseFloat(item.paid_money).toFixed(2)
                        if (item.total_cash > 0) {
                            item.income = parseFloat(item.paid_money - item.total_cash)
                        } else {
                            item.income = 0
                        }
                        if (item.income < min_income) {
                            min_income = item.income
                        }
                        if (item.income > max_income) {
                            max_income = item.income
                        }
                    }
                    this.sum_total_cost = parseFloat(this.sum_total_cost).toFixed(2)
                    this.sum_total_cash = parseFloat(this.sum_total_cash).toFixed(2)
                    this.sum_paid_money = this.sum_paid_money.toFixed(2)
                    this.sum_income = parseFloat(this.sum_paid_money - this.sum_total_cash).toFixed(2)
                    let total_income = max_income
                    if (min_income < 0) {
                        total_income += Math.abs(min_income)
                        this.income_center_rate = parseInt(Math.abs(min_income) / total_income * 100);
                    }
                    for (let item of this.items) {
                        if (item.income >= 0) {
                            item.income_rate = parseInt(item.income / max_income * 100);
                        } else {
                            item.income_rate = -parseInt(Math.abs(item.income) / Math.abs(min_income) * 100);
                        }
                        item.income = item.income.toFixed(2)
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleItemView(oid) {
            this.$router.push('/orders/' + oid + '/edit')
        },
        formatDateYmd(date) {
            let str = date.getFullYear() + "-"
            if (date.getMonth() < 9) {
                str += "0"
            }
            str += (date.getMonth() + 1) + "-"
            if (date.getDate() < 10) {
                str += "0"
            }
            return str + date.getDate()
        },
        paidRate(paid, total) {
            if (paid == 0) {
                return '0%'
            }
            return (paid / total * 100).toFixed(2) + '%';
        },
        paidAvg(cash, money, orders) {
            if (money == 0 || orders == 0) {
                return ''
            }
            let ret = (money / orders).toFixed(2)
            if (cash != 0) {
                let val = (cash / orders) - (money / orders)
                ret += '/'
                if (val > 0) {
                    ret += '+'
                }
                ret += val.toFixed(2)
            }
            return ret
        }
    }
}
</script>