<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>{{ $route.params.domain }} 域名列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加域名</span>
                    </div>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="200">#</th>
                            <th>域名</th>
                            <th width="200">端口</th>
                            <th width="200">创建时间</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.port }}</td>
                            <td>{{ item.addtime }}</td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    props: {
        msg: String
    },
    components: {
        Pagination
    },
    mounted() {
        this.fetchList()
    },
    data() {
        return {
            page: 1,
            page_size: 10,
            total_count: 0,
            baseItems: [],
            items: [],
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchList() {
            this.startLoading()
            this.$api.post('host/domains', { host_index: this.$route.params.host_index, site_id: this.$route.params.site_id }).then(res => {
                if (res.data.success) {
                    this.baseItems = res.data.data
                    this.total_count = this.baseItems.length
                    this.handlePage(1)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleNew() {
            this.$router.push('domains/new')
        },
        handlePage(page) {
            let start = (page - 1) * this.page_size;
            let end = start + this.page_size;
            if (end > this.total_count) {
                end = this.total_count
            }
            this.items = this.baseItems.slice(start, end)
            this.page = page
        }
    }
}
</script>