<template>
    <div id="app">
        <Loading ref="loading"></Loading>
        <div class="header" v-if="isLoggedIn">
            <div class="wrapper">
                <div class="project-name">
                    <img src="./assets/images/project_logo.png">
                    <h3>{{ projectName }}</h3>
                </div>
                <div class="nav">
                    <div class="nav-items">
                        <div class="nav-item"><router-link to="/"><span class="text">首页</span></router-link></div>
                        <div :class="{ 'nav-item': true, 'active': navFlag == 1 }"
                            v-if="userInfo.role_permissions.includes('ORDER_LIST') && userInfo.role_permissions.includes('ORDER_MODIFY_LIST')">
                            <b><span class="text">订单管理<i class="arrow"></i></span></b>
                            <div class="menu">
                                <ul>
                                    <li v-if="userInfo.role_permissions.includes('ORDER_LIST')"><router-link
                                            to="/orders">订单列表</router-link></li>
                                    <li v-if="userInfo.role_permissions.includes('ORDER_MODIFY_LIST')"><router-link
                                            to="/orders/modifies">结果审核</router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div :class="{ 'nav-item': true, 'active': navFlag == 1 }"
                            v-else-if="userInfo.role_permissions.includes('ORDER_LIST')">
                            <router-link to="/orders"><span class="text">订单列表</span></router-link>
                        </div>
                        <div :class="{ 'nav-item': true, 'active': navFlag == 2 }"
                            v-if="showAdNav && (userInfo.role_permissions.includes('AD_ACCOUNT_LIST') || userInfo.role_permissions.includes('AD_ACCOUNT_REPORT') || userInfo.role_permissions.includes('AD_MEMBER_REPORT'))">
                            <b><span class="text">推广管理<i class="arrow"></i></span></b>
                            <div class="menu">
                                <ul>
                                    <li v-if="userInfo.role_permissions.includes('AD_ACCOUNT_LIST')"><router-link
                                            to="/ad_accounts">推广账户</router-link></li>
                                    <li v-if="userInfo.role_permissions.includes('AD_ACCOUNT_REPORT')"><router-link
                                            to="/ad_reports/account_report">账户报表</router-link></li>
                                    <li v-if="userInfo.role_permissions.includes('AD_MEMBER_REPORT')"><router-link
                                            to="/ad_reports/member_report">运营报表</router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div :class="{ 'nav-item': true, 'active': navFlag == 3 }"
                            v-if="userInfo.role_permissions.includes('WEIXIN_LIST') || userInfo.role_permissions.includes('WEIXIN_GROUP_LIST')">
                            <b><span class="text">微信管理<i class="arrow"></i></span></b>
                            <div class="menu">
                                <ul>
                                    <li v-if="userInfo.role_permissions.includes('WEIXIN_LIST')"><router-link
                                            to="/weixins">微信号管理</router-link></li>
                                    <li v-if="userInfo.role_permissions.includes('WEIXIN_GROUP_LIST')"><router-link
                                            to="/weixin_groups">微信组管理</router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div :class="{ 'nav-item': true, 'active': navFlag == 4 }"
                            v-if="userInfo.role_permissions.includes('DOMAIN_LIST') || userInfo.role_permissions.includes('HOST_LIST') || userInfo.role_permissions.includes('MEMBER_LIST') || userInfo.role_permissions.includes('PAY_GROUP_LIST')">
                            <b><span class="text">系统配置<i class="arrow"></i></span></b>
                            <div class="menu">
                                <ul>
                                    <li v-if="userInfo.role_permissions.includes('HOST_LIST')"><router-link
                                            to="/hosts">域名绑定</router-link></li>
                                    <li v-if="userInfo.role_permissions.includes('DOMAIN_LIST')"><router-link
                                            to="/domains">域名解析</router-link></li>
                                    <li
                                        v-if="(userInfo.role_permissions.includes('DOMAIN_LIST') || userInfo.role_permissions.includes('HOST_LIST')) && (userInfo.role_permissions.includes('PAY_GROUP_LIST') || userInfo.role_permissions.includes('MEMBER_LIST'))">
                                        <span class="divider"></span>
                                    </li>
                                    <li v-if="userInfo.role_permissions.includes('PAY_GROUP_LIST')"><router-link
                                            to="/pay_groups">支付组配置</router-link></li>
                                    <li
                                        v-if="userInfo.role_permissions.includes('PAY_GROUP_LIST') && userInfo.role_permissions.includes('MEMBER_LIST')">
                                        <span class="divider"></span>
                                    </li>
                                    <li v-if="userInfo.role_permissions.includes('MEMBER_LIST')"><router-link to="/members">账号管理</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <img src="./assets/images/avatar_default.png" width="32" height="32">
                        <dl>
                            <dt>{{ userInfo.name }}({{ userInfo.username }})</dt>
                            <dd>{{ userInfo.role_name }}</dd>
                        </dl>
                        <div class="menu">
                            <ul>
                                <li><router-link to="/profile">个人信息</router-link></li>
                                <li><span class="divider"></span></li>
                                <li><b @click="logout">退出登录</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="wrapper">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from './components/Loading.vue'
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'App',
    components: {
        Loading
    },
    computed: {
        ...mapState(['isLoading', 'isLoggedIn', 'userInfo'])
    },
    data() {
        return {
            navFlag: 0,
            projectName: '',
            showAdNav: false,
            orderModify: false
        }
    },
    methods: {
        ...mapMutations(['setLoggedIn']),
        logout() {
            this.setLoggedIn(null)
            this.$router.push('/login')
        },
        setHtmlFontSize() {
            let width = document.body.clientWidth
            let fontsize
            if (width <= 240) {
                fontsize = 13.33
            } else if (width >= 540) {
                fontsize = 16
            } else {
                fontsize = (width / 18).toFixed(2)
            }
            document.getElementsByTagName("html")[0].style.fontSize = `${fontsize}px`
        },
        updateNavFlag(path) {
            if (path.startsWith('/orders')) {
                this.navFlag = 1
            } else if (path.startsWith('/ad_reports') || path.startsWith('/ad_accounts')) {
                this.navFlag = 2
            } else if (path.startsWith('/weixins') || path.startsWith('/weixin_groups')) {
                this.navFlag = 3
            } else if (path.startsWith('/members') || path.startsWith('/webs') || path.startsWith('/domains') || path.startsWith('/hosts')) {
                this.navFlag = 4
            } else {
                this.navFlag = 0
            }
        }
    },
    mounted() {
        this.projectName = process.env.VUE_APP_TITLE
        this.showAdNav = process.env.VUE_APP_AD_NAV === 'true'
        this.orderModify = process.env.VUE_APP_ORDER_MODIFY === 'true'
        document.title = this.projectName
        this.updateNavFlag(this.$route.path)
        window.addEventListener("DOMContentLoaded", this.setHtmlFontSize)
        window.addEventListener("resize", this.setHtmlFontSize)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setHtmlFontSize)
        window.removeEventListener("DOMContentLoaded", this.setHtmlFontSize)
    },
    watch: {
        '$route'(to) {
            this.updateNavFlag(to.path)
        },
        isLoading(newVal) {
            if (newVal) {
                this.$refs.loading.start();
            } else {
                this.$refs.loading.done();
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>