<template>
    <div class="detail-wrap">
        <div class="page-header">
            <h3>用户编辑</h3>
            <div class="actions">
                <span class="btn btn-light" @click="toList">返回列表</span>
            </div>
        </div>
        <MemberForm v-model="data" @handleSubmit="handleSubmit" :edit="true" :role="true"></MemberForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import MemberForm from '../../components/MemberForm.vue'

export default {
    name: 'WeixinGroupEdit',
    components: {
        MemberForm
    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('member/detail', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        toList() {
            this.$router.push('/members')
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('member/update', data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>