<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">用户名:</span>
                <input type="text" v-model.trim="data.username" class="form-control col">
                <span class="form-label">媒体类型:</span>
                <Selection
                    :items="{ 'Baidu': '百度推广', '360': '360搜索', 'Sogou': '搜狗搜索', 'Shenma': '神马搜索', 'Douyin': '抖音', 'Kuaishou': '快手', 'Weibo': '新浪微博', 'VIVO': 'VIVO', 'OPPO': 'OPPO' }"
                    placeholder="请选择媒体类型" v-model="data.media_type" style-class="col"></Selection>
            </div>
            <div class="form-item">
                <span class="form-label">密码:</span>
                <input type="text" v-model.trim="data.password" class="form-control col">
                <span class="form-label">媒体账户ID:</span>
                <input type="text" v-model.trim="data.media_account_id" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">密保主手机:</span>
                <input type="text" v-model.trim="data.mobile" class="form-control col">
                <span class="form-label">密保子手机:</span>
                <input type="text" v-model.trim="data.sub_mobile" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">是否开启:</span>
                <div class="form-switch col"><input type="checkbox" v-model="data.is_enabled"></div>
                <span class="form-label" v-if="userInfo.role_permissions.includes('ADMIN')">跟踪搜索词:</span>
                <div class="form-switch col" v-if="userInfo.role_permissions.includes('ADMIN')"><input type="checkbox" v-model="data.is_baidu_tongji"></div>
            </div>
            <div class="form-item">
                <span class="form-label">支付组:</span>
                <Selection :items="payGroups" placeholder="请选择支付组" v-model="data.pay_group_id" style-class="col">
                </Selection>
            </div>
            <div class="form-item" v-if="userInfo.role_permissions.includes('ADMIN')">
                <span class="form-label">微信组:</span>
                <ul class="children col">
                    <li v-for="(item, index) in weixinGroups" :key="index" class="form-check">
                        <label><input type="checkbox" v-model="data.weixin_group_ids" :value="index">{{ item }}</label>
                    </li>
                </ul>
            </div>
            <div class="form-item" v-if="userInfo.role_permissions.includes('ADMIN')">
                <span class="form-label">归属运营:</span>
                <Selection :items="members" placeholder="请选择归属运营" v-model="data.member_ids" style-class="col"></Selection>
            </div>
            <div class="form-item">
                <span class="form-label">公司名称:</span>
                <input type="text" v-model.trim="data.company_name" class="form-control col">
                <span class="form-label">电话:</span>
                <input type="text" v-model.trim="data.tel" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">公司地址:</span>
                <input type="text" v-model.trim="data.address" class="form-control col">
                <span class="form-label">ICP:</span>
                <input type="text" v-model.trim="data.icp" class="form-control col">
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'
import { mapState } from 'vuex'

export default {
    name: 'AdAccountForm',
    components: {
        Selection
    },
    computed: {
        ...mapState(['userInfo'])
    },
    props: {
        value: Object
    },
    data() {
        return {
            members: [],
            adAccounts: [],
            data: this.value,
            weixinGroups: [],
            payGroups: []
        }
    },
    mounted() {
        this.fetchPayGroups()
        this.fetchWeixinGroups()
        this.fetchMembers()
        this.fetchAdAccounts()
    },
    methods: {
        fetchPayGroups() {
            this.$api.get('pay_groups', this.query).then(res => {
                if (res.data.success) {
                    this.payGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups', this.query).then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
        fetchMembers() {
            this.$api.get('members').then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchAdAccounts() {
            this.$api.get('ad_accounts').then(res => {
                if (res.data.success) {
                    this.adAccounts = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handlePlus() {
            this.data.balance_ad_account_ids.push('')
        },
        handleMinus(index) {
            this.data.balance_ad_account_ids.splice(index, 1)
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>