<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>微信号列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加微信号</span>
                    </div>
                </div>
                <form class="search" @submit.prevent="onSearch">
                    <div class="search-item">
                        <label>微信组:</label>
                        <Selection :items="weixinGroups" placeholder="全部" v-model="query.weixin_group_id">
                        </Selection>
                    </div>
                    <div class="search-item">
                        <label>是否开启:</label>
                        <Selection :items="['未开启', '已开启']" placeholder="全部" v-model="query.is_enabled">
                        </Selection>
                    </div>
                    <div class="search-item">
                        <label>关键词:</label>
                        <input type="text" class="form-control kw" placeholder="如：名称/微信号" v-model="query.kw">
                    </div>
                    <div class="search-item buttons">
                        <button type="submit" class="btn btn-primary">查询</button>
                        <span class="divider"></span>
                        <button type="button" class="btn btn-light" @click="onClear">清空</button>
                    </div>
                </form>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="38">#</th>
                            <th>微信号</th>
                            <th width="90">微信类型</th>
                            <th width="80">二维码</th>
                            <th width="80">是否开启</th>
                            <th width="160">微信组</th>
                            <th width="120">绑定账号</th>
                            <th width="140">创建时间</th>
                            <th width="160">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.id }}</td>
                            <td>{{ item.weixin_no }}</td>
                            <td><span :class="['badge', getWeixinTypeStyle(item.weixin_type)]">{{ getWeixinType(item.weixin_type) }}</span></td>
                            <td>
                                <a :href="item.qrcode_path" target="_blank" v-if="item.qrcode_path">已设置</a>
                            </td>
                            <td><span class="form-switch"><input type="checkbox" v-model="item.is_enabled"
                                        @change="handleItemEnable(item, $event)" value="1"></span></td>
                            <td>
                                <span v-for="(weixin_group_id, weixin_group_index) in item.weixin_group_ids"
                                    :key="weixin_group_index" class="badge text-bg-light">{{
                            weixinGroups[weixin_group_id] }}</span>
                            </td>
                            <td>{{ members[item.member_id] }}</td>
                            <td>{{ item.created_at }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemEdit(item.id)">编辑</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'
import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination,
        Selection
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchWeixinGroups()
        this.fetchMembers()
        this.fetchList()
    },
    data() {
        return {
            weixinGroups: [],
            members: [],
            query: {
                weixin_group_id: null,
                member_id: null,
                is_enabled: 1,
                kw: ''
            },
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.query = {
                weixin_id: null,
                member_id: null,
                is_enabled: 1,
                kw: ''
            }
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups', this.query).then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchMembers() {
            this.$api.get('members', this.query).then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('weixin/list', { ...this.query, ...{ page: page, page_size: this.page_size } }).then(res => {
                if (res.data.success) {
                    // this.items = res.data.data.items

                    this.items = []
                    for (let item of res.data.data.items) {
                        if (item.weixin_group_ids) {
                            item.weixin_group_ids = item.weixin_group_ids.split(',')
                        } else {
                            item.weixin_group_ids = []
                        }
                        this.items.push(item)
                    }
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleItemEdit(id) {
            this.$router.push('/weixins/' + id + '/edit')
        },
        handleNew() {
            this.$router.push('/weixins/new')
        },
        handleItemDelete(data) {
            if (confirm('确定要删除【' + data.weixin_no + '】吗？')) {
                this.$api.post('weixin/delete', { id: data.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        handleItemEnable(item, event) {
            let newVal = event.target.checked ? 1 : 0;
            this.$api.post('weixin/update', { id: item.id, is_enabled: newVal }).then(res => {
                if (!res.data.success) {
                    item.is_enabled = newVal == 1 ? 0 : 1;
                }
            }).catch(error => {
                item.is_enabled = newVal == 1 ? 0 : 1;
                console.log(error)
            })
        },
        getWeixinType(type) {
            if (type == 1) {
                return '个人微信';
            } else if (type == 2) {
                return '企业微信';
            } else if (type == 3) {
                return '企微获客';
            }
            return '';
        },
        getWeixinTypeStyle(type) {
            if (type == 1) {
                return 'text-bg-success';
            } else if (type == 2) {
                return 'text-bg-primary';
            } else if (type == 3) {
                return 'text-bg-warning';
            }
            return '';
        }
    }
}
</script>