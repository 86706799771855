<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>域名列表</h3>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th>域名</th>
                            <th width="220">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.domain }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemDns(item.domain)">DNS解析</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchRoles()
        this.fetchList()
    },
    data() {
        return {
            roles: [],
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('domain/list', { page: page, page_size: this.page_size }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        fetchRoles() {
            this.$api.get('roles').then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleItemDns(domain) {
            this.$router.push('domains/' + domain + '/dns')
        }
    }
}
</script>