<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>{{ $route.params.oid }}</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <form @submit.prevent="handleSubmit" class="form">
            <div class="form-item">
                <span class="form-label">订单编号:</span>
                <p class="text col2">{{ data.oid }}</p>
            </div>
            <OrderForm ref="form" v-model="data"></OrderForm>
            <div class="form-item"
                v-if="userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('AD_OPTIMIZER')">
                <span class="form-label">入口网址:</span>
                <p class="text col2"><a :href="data.first_url" target="_blank">{{ data.first_url }}</a></p>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
                <button type="button" class="btn btn-outline-primary" v-if="orderModify && data.type == 8"
                    @click="handleModify">修改结果</button>
            </div>
        </form>
        <div v-if="orderLog">
            <div class="page-heading">
                <h3>操作记录</h3>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <th width="220">操作成员</th>
                        <th>操作记录</th>
                        <th width="220">操作时间</th>
                    </tr>
                    <tr v-if="total_count == -1">
                        <td colspan="3" class="text-center">加载中……</td>
                    </tr>
                    <tr v-if="total_count == 0">
                        <td colspan="3" class="text-center">暂无操作记录！</td>
                    </tr>
                    <tr v-else v-for=" (log, i) in logs" :key="i">
                        <td>{{ log.member_name }}({{ log.member_username }})</td>
                        <td>{{ log.content }}</td>
                        <td>{{ log.created_at }}</td>
                    </tr>
                </table>
            </div>
            <div class="page-info">
                <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                    @handlePage="handlePage">
                </Pagination>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex'

import OrderForm from '../../components/OrderForm.vue'

export default {
    name: 'OrderShow',
    components: {
        OrderForm
    },
    data() {
        return {
            orderModify: process.env.VUE_APP_ORDER_MODIFY === 'true',
            orderLog: process.env.VUE_APP_ORDER_LOG === 'true',
            data: {},
            logs: [],
            total_count: -1,
            page: 1,
            page_size: 10
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('order/detail', { oid: this.$route.params.oid }).then(res => {
                if (res.data.success) {
                    res.data.data.data = JSON.parse(decodeURIComponent(res.data.data.data))
                    this.data = res.data.data
                    if (this.orderLog) {
                        this.fetchLogs()
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        fetchLogs(page = 1) {
            this.$api.post('order/logs', { oid: this.$route.params.oid, page: page, page_size: this.page_size }).then(res => {
                if (res.data.success) {
                    this.logs = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        toList() {
            this.$router.push('/orders')
        },
        handlePage(page) {
            this.fetchLogs(page)
        },
        handleSubmit() {
            this.startLoading()
            let localData = { ...this.data }
            localData.data = this.$refs.form.getEncodeData()
            this.$api.post('order/update', localData).then(res => {
                alert(res.data.message)
                if (res.data.success) {
                    this.fetchLogs()
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleModify() {
            this.$router.push('modify/bzjp')
        }
    }
}
</script>