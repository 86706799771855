<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>{{ $route.params.domain }} 解析列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加解析</span>
                    </div>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="200">主机记录</th>
                            <th width="200">记录类型</th>
                            <th>记录值</th>
                            <th width="200">创建时间</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.RR }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.value }}</td>
                            <td>{{ formatDateYmdHis(item.createTimestamp) }}</td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchRoles()
        this.fetchList()
    },
    data() {
        return {
            roles: [],
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('domain/dns', { domain: this.$route.params.domain, page: page, page_size: this.page_size }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        fetchRoles() {
            this.$api.get('roles').then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleItemDns(domain) {
            this.$router.push('domains/' + domain + '/dns')
        },
        formatDateYmdHis(timestamp) {
            const date = new Date(timestamp)
            let str = date.getFullYear() + "-"
            if (date.getMonth() < 9) {
                str += "0"
            }
            str += (date.getMonth() + 1) + "-"
            if (date.getDate() < 10) {
                str += "0"
            }
            str += date.getDate() + " "
            if (date.getHours() < 10) {
                str += "0"
            }
            str += date.getHours() + ":"
            if (date.getMinutes() < 10) {
                str += "0"
            }
            str += date.getMinutes() + ":"
            if (date.getSeconds() < 10) {
                str += "0"
            }
            return str + date.getSeconds()
        },
        handleNew() {
            this.$router.push('dns/new')
        }
    }
}
</script>