<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>微信组列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加微信组</span>
                    </div>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th>名称</th>
                            <th>售卖价格</th>
                            <th width="220">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.name }}</td>
                            <td>{{ item.moneys }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemEdit(item.id)">编辑</span> <span
                                    class="btn btn-light btn-s" @click="handleItemDelete(item.id)">删除</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchList()
    },
    data() {
        return {
            weixin_groups: [],
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('weixin_group/list', { page: page, page_size: this.page_size }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.query.page = page
            this.fetchList()
        },
        handleItemEdit(id) {
            this.$router.push('/weixin_groups/' + id + '/edit')
        },
        handleNew() {
            this.$router.push('/weixin_groups/new')
        },
        handleItemDelete(id) {
            this.$api.post('weixin_group/delete', { id: id }).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                    this.fetchList(this.page)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>