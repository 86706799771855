<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">支付组名称:</span>
                <input type="text" v-model="data.name" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">微信:</span>
                <Selection placeholder="请选择微信支付接口" v-model="data.weixin_pay_channel_id" remote="pay_channels?code=Weixin" style-class="col">
                </Selection>
                <span class="form-label">支付宝:</span>
                <Selection placeholder="请选择支付宝接口" v-model="data.alipay_pay_channel_id" remote="pay_channels?code=Alipay" style-class="col">
                </Selection>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'PayGroupForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            data: this.value
        }
    },
    mounted() {
        this.fetchMembers()
        this.fetchWeixinMps()
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>