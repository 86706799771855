<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">姓名:</span>
                <input type="text" v-model="data.name" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">用户名:</span>
                <input type="text" v-model="data.username" class="form-control col">
                <span class="form-label">密码:</span>
                <input type="text" v-model="data.password" class="form-control col">
            </div>
            <div class="form-item" v-if="role">
                <span class="form-label">角色/权限:</span>
                <Selection :items="roles" placeholder="请选择角色/权限" v-model="data.role_id" style-class="col"></Selection>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'WeixinGroupForm',
    components: {
        Selection
    },
    props: {
        value: Object,
        role: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            roles: [],
            data: {
            }
        }
    },
    mounted() {
        this.fetchRoles()
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
        fetchRoles() {
            this.$api.get('roles').then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>