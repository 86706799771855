<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>{{ $route.params.ip }} 站点列表</h3>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th>系统名称</th>
                            <th>主域名</th>
                            <th width="170">添加时间</th>
                            <th width="180">操作</th>
                        </tr>
                        <tr v-for=" (item, index) in items" :key="index">
                            <td>{{ item.name }}</td>
                            <td>{{ item.domain }}</td>
                            <td>{{ item.addtime }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemSiteDomains(item.id, item.domain)">域名绑定</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchList()
    },
    data() {
        return {
            items: [],
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchList() {
            this.startLoading()
            this.$api.post('host/sites', { host_index: this.$route.params.host_index }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleItemSiteDomains(id, domain) {
            this.$router.push('sites/' + id + '/' + domain + '/domains')
        }
    }
}
</script>