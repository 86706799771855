<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">域名:</span>
                <input type="text" v-model="data.hostname" class="form-control col">
            </div>
            <div class="form-item" v-if="showPayGroup">
                <span class="form-label">支付组:</span>
                <Selection :items="payGroups" placeholder="请选择支付组" v-model="data.pay_group_id" style-class="col">
                </Selection>
            </div>
            <div class="form-item">
                <span class="form-label">公司名称:</span>
                <input type="text" v-model="data.company_name" class="form-control col">
                <span class="form-label">地址:</span>
                <input type="text" v-model="data.address" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">电话:</span>
                <input type="text" v-model="data.tel" class="form-control col">
                <span class="form-label">ICP:</span>
                <input type="text" v-model="data.icp" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">售卖价格:</span>
                <input type="text" v-model="data.money" class="form-control col">
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'WebForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            showPayGroup: process.env.VUE_APP_PAY_GROUP === 'true',
            weixinGroups: [],
            payGroups: [],
            data: {
                weixin_group_id: 0,
                pay_group_id: 0
            }
        }
    },
    mounted() {
        this.fetchWeixinGroups()
        this.fetchPayGroups()
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups', this.query).then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchPayGroups() {
            this.$api.get('pay_groups', this.query).then(res => {
                if (res.data.success) {
                    this.payGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>