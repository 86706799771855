<template>
    <div class="login">
        <div class="login-banner">

        </div>
        <form @submit.prevent="handleSubmit" class="login-form">
            <div class="form-heading">
                <h3>{{ projectName }}</h3>
            </div>
            <div class="form-item">
                <label>用户名</label>
                <input type="text" class="form-control" placeholder="请输入用户名" v-model="username" required>
            </div>
            <div class="form-item">
                <label>密码</label>
                <input type="password" class="form-control" placeholder="请输入密码" v-model="password" required>
            </div>
            <div class="form-item">
                <button type="submit" class="btn btn-primary">登 录</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'AuthLogin',
    props: {
        msg: String
    },
    data() {
        return {
            username: '',
            password: '',
            projectName: ''
        }
    },
    mounted() {
        this.projectName = process.env.VUE_APP_TITLE
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading', 'setLoggedIn', 'setUserInfo', 'setOrderTypes', 'setOrderStatuses', 'setPayTypes']),
        handleSubmit() {
            this.startLoading()
            let that = this;
            const params = {
                'username': this.username,
                'password': this.password
            }
            this.$api.post('auth/login', params).then(function (res) {
                if (res.data.success) {
                    that.setLoggedIn(res.data.data.token)
                    that.setUserInfo(res.data.data.user_info)
                    that.setOrderTypes(res.data.data.order_types)
                    that.setOrderStatuses(res.data.data.order_statuses)
                    that.setPayTypes(res.data.data.pay_types)
                    const redirect = that.$route.query.redirect
                    that.$router.push(redirect || '/')
                } else {
                    alert(res.data.message)
                }
            }).catch(function (res) {
                console.log(res)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/login.scss';
</style>
