<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>折扣列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加折扣</span>
                    </div>
                </div>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th>推广账户</th>
                            <th width="140">媒体产品类型</th>
                            <th width="100">折扣率</th>
                            <th width="120">开始时间</th>
                            <th width="120">结束时间</th>
                            <th width="140">结束时间</th>
                            <th width="140">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.ad_account_username }}</td>
                            <td>{{ item.media_product }}</td>
                            <td>{{ item.rate }}</td>
                            <td>{{ item.start_date }}</td>
                            <td>{{ item.end_date }}</td>
                            <td>{{ item.created_at }}</td>
                            <td><span class="btn btn-light btn-s" @click="handleItemEdit(item.id)">编辑</span> <span
                                    class="btn btn-light btn-s" @click="handleItemDelete(item)">删除</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'AdAccountDiscountList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchList()
    },
    data() {
        return {
            weixin_groups: [],
            ad_account_id: this.$route.query.ad_account_id,
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.query.kw = ''
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('ad_account_discount/list', { ad_account_id: this.$route.params.id, page: page, page_size: this.page_size }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleNew() {
            this.$router.push('discounts/new')
        },
        handleItemEdit(id) {
            this.$router.push('discounts/' + id + '/edit')
        },
        handleItemDelete(item) {
            if (confirm('确定要删除折扣【' + item.hostname + '-' + item.code + '】吗？')) {
                this.$api.post('ad_account_discount/delete', { id: item.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    }
}
</script>
