import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'

Vue.use(Vuex)
Vue.use(VueClipboard)

let localIsLoggedIn = false
let localUserInfo = {}
let localOrderTypes = {}
let localOrderStatuses = {}
let localPayTypes = {}
if (localStorage.getItem('token') != null) {
    localIsLoggedIn = true
}
if (localStorage.getItem('userInfo') != null) {
    localUserInfo = JSON.parse(localStorage.getItem('userInfo'))
}
if (localStorage.getItem('orderTypes') != null) {
    localOrderTypes = JSON.parse(localStorage.getItem('orderTypes'))
}
if (localStorage.getItem('orderStatuses') != null) {
    localOrderStatuses = JSON.parse(localStorage.getItem('orderStatuses'))
}
if (localStorage.getItem('payTypes') != null) {
    localPayTypes = JSON.parse(localStorage.getItem('payTypes'))
}
const store = new Vuex.Store({
    state: {
        isLoading: false,
        isLoggedIn: localIsLoggedIn,
        userInfo: localUserInfo,
        orderTypes: localOrderTypes,
        orderStatuses: localOrderStatuses,
        payTypes: localPayTypes
    },
    mutations: {
        setLoggedIn(state, token) {
            state.token = token
            if (token == null) {
                state.isLoggedIn = false
                localStorage.removeItem("token")
            } else {
                localStorage.setItem("token", token)
                state.isLoggedIn = true
            }
        },
        setUserInfo(state, data) {
            state.userInfo = data
            if (data == null) {
                localStorage.removeItem("userInfo")
            } else {
                localStorage.setItem("userInfo", JSON.stringify(data))
            }
        },
        setOrderTypes(state, data) {
            state.orderTypes = data
            if (data == null) {
                localStorage.removeItem("orderTypes")
            } else {
                localStorage.setItem("orderTypes", JSON.stringify(data))
            }
        },
        setOrderStatuses(state, data) {
            state.orderStatuses = data
            if (data == null) {
                localStorage.removeItem("orderStatuses")
            } else {
                localStorage.setItem("orderStatuses", JSON.stringify(data))
            }
        },
        setPayTypes(state, data) {
            state.payTypes = data
            if (data == null) {
                localStorage.removeItem("payTypes")
            } else {
                localStorage.setItem("payTypes", JSON.stringify(data))
            }
        },
        startLoading(state) {
            state.isLoading = true;
        },
        finishLoading(state) {
            state.isLoading = false;
        }
    }
})

Vue.use(VueRouter)

const API = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 1000 * 60
});
API.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么，例如加入 token 到 headers  
        const token = localStorage.getItem('token'); // 从 localStorage 或其他存储中获取 token  
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`; // 假设你使用的是 Bearer token  
        }
        return config;
    },
    error => {
        // 对请求错误做些什么  
        return Promise.reject(error);
    }
);
Vue.prototype.$api = API

import App from './App.vue'

import Home from './pages/Home.vue'

import AuthLogin from './pages/Auth/login.vue'

import OrderIndex from './pages/Order/index.vue'
import OrderModify from './pages/OrderModify/index.vue'
import OrderModifyBzjp from './pages/OrderModify/bzjp.vue'
import OrderNew from './pages/Order/new.vue'
import OrderEdit from './pages/Order/edit.vue'

import WeixinIndex from './pages/Weixin/index.vue'
import WeixinNew from './pages/Weixin/new.vue'
import WeixinEdit from './pages/Weixin/edit.vue'

import WeixinGroupIndex from './pages/WeixinGroup/index.vue'
import WeixinGroupNew from './pages/WeixinGroup/new.vue'
import WeixinGroupEdit from './pages/WeixinGroup/edit.vue'

import WebIndex from './pages/Web/index.vue'
import WebNew from './pages/Web/new.vue'
import WebEdit from './pages/Web/edit.vue'

import HostIndex from './pages/Host/index.vue'
import HostSites from './pages/Host/sites.vue'
import HostSiteDomains from './pages/Host/siteDomains.vue'
import HostSiteDomainNew from './pages/Host/siteDomainNew.vue'

import AdTagIndex from './pages/AdTag/index.vue'
import AdTagNew from './pages/AdTag/new.vue'
import AdTagEdit from './pages/AdTag/edit.vue'

import AdAccountIndex from './pages/AdAccount/index.vue'
import AdAccountNew from './pages/AdAccount/new.vue'
import AdAccountEdit from './pages/AdAccount/edit.vue'

import AdAccountDiscountIndex from './pages/AdAccountDiscount/index.vue'
import AdAccountDiscountNew from './pages/AdAccountDiscount/new.vue'
import AdAccountDiscountEdit from './pages/AdAccountDiscount/edit.vue'

import AdReportAccountReport from './pages/AdReport/accountReport.vue'
import AdReportMemberReport from './pages/AdReport/memberReport.vue'

import MemberIndex from './pages/Member/index.vue'
import MemberNew from './pages/Member/new.vue'
import MemberEdit from './pages/Member/edit.vue'

import DomainIndex from './pages/Domain/index.vue'
import DomainDns from './pages/Domain/dns.vue'
import DomainDnsNew from './pages/Domain/dnsNew.vue'

import PayGroupIndex from './pages/PayGroup/index.vue'
import PayGroupNew from './pages/PayGroup/new.vue'
import PayGroupEdit from './pages/PayGroup/edit.vue'

import Profile from './pages/Profile.vue'

const routes = [
    { path: '/login', component: AuthLogin },
    { path: '/profile', component: Profile, meta: { requiresAuth: true } },
    { path: '/', component: Home, meta: { requiresAuth: true } },
    { path: '/orders', component: OrderIndex, meta: { requiresAuth: true } },
    { path: '/orders/new', component: OrderNew, meta: { requiresAuth: true } },
    { path: '/orders/modifies', component: OrderModify, meta: { requiresAuth: true } },
    { path: '/orders/:oid/edit', component: OrderEdit, meta: { requiresAuth: true } },
    { path: '/orders/:oid/modify/bzjp', component: OrderModifyBzjp, meta: { requiresAuth: true } },
    { path: '/weixins', component: WeixinIndex, meta: { requiresAuth: true } },
    { path: '/weixins/new', component: WeixinNew, meta: { requiresAuth: true } },
    { path: '/weixins/:id/edit', component: WeixinEdit, meta: { requiresAuth: true } },
    { path: '/weixin_groups', component: WeixinGroupIndex, meta: { requiresAuth: true } },
    { path: '/weixin_groups/new', component: WeixinGroupNew, meta: { requiresAuth: true } },
    { path: '/weixin_groups/:id/edit', component: WeixinGroupEdit, meta: { requiresAuth: true } },
    { path: '/webs', component: WebIndex, meta: { requiresAuth: true } },
    { path: '/webs/new', component: WebNew, meta: { requiresAuth: true } },
    { path: '/webs/:id/edit', component: WebEdit, meta: { requiresAuth: true } },
    { path: '/hosts', component: HostIndex, meta: { requiresAuth: true } },
    { path: '/hosts/:host_index/:ip/sites', component: HostSites, meta: { requiresAuth: true } },
    { path: '/hosts/:host_index/:ip/sites/:site_id/:domain/domains', component: HostSiteDomains, meta: { requiresAuth: true } },
    { path: '/hosts/:host_index/:ip/sites/:site_id/:domain/domains/new', component: HostSiteDomainNew, meta: { requiresAuth: true } },
    { path: '/ad_tags', component: AdTagIndex, meta: { requiresAuth: true } },
    { path: '/ad_tags/new', component: AdTagNew, meta: { requiresAuth: true } },
    { path: '/ad_tags/:id/edit', component: AdTagEdit, meta: { requiresAuth: true } },
    { path: '/ad_accounts', component: AdAccountIndex, meta: { requiresAuth: true } },
    { path: '/ad_accounts/new', component: AdAccountNew, meta: { requiresAuth: true } },
    { path: '/ad_accounts/:id/edit', component: AdAccountEdit, meta: { requiresAuth: true } },
    { path: '/ad_accounts/:id/discounts', component: AdAccountDiscountIndex, meta: { requiresAuth: true } },
    { path: '/ad_accounts/:id/discounts/new', component: AdAccountDiscountNew, meta: { requiresAuth: true } },
    { path: '/ad_accounts/:id/discounts/:discount_id/edit', component: AdAccountDiscountEdit, meta: { requiresAuth: true } },
    { path: '/ad_reports/account_report', component: AdReportAccountReport, meta: { requiresAuth: true } },
    { path: '/ad_reports/member_report', component: AdReportMemberReport, meta: { requiresAuth: true } },
    { path: '/members', component: MemberIndex, meta: { requiresAuth: true } },
    { path: '/members/new', component: MemberNew, meta: { requiresAuth: true } },
    { path: '/members/:id/edit', component: MemberEdit, meta: { requiresAuth: true } },
    { path: '/domains', component: DomainIndex, meta: { requiresAuth: true } },
    { path: '/domains/:domain/dns', component: DomainDns, meta: { requiresAuth: true } },
    { path: '/domains/:domain/dns/new', component: DomainDnsNew, meta: { requiresAuth: true } },
    { path: '/pay_groups', component: PayGroupIndex, meta: { requiresAuth: true } },
    { path: '/pay_groups/new', component: PayGroupNew, meta: { requiresAuth: true } },
    { path: '/pay_groups/:id/edit', component: PayGroupEdit, meta: { requiresAuth: true } },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth && !store.state.isLoggedIn) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    } else {
        next()
    }
})

router.afterEach(() => {
    window.scroll(0, 0)
})

Vue.config.productionTip = false

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')
