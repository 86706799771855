<template>
    <div class="order-list">
        <div class="page-header">
            <h3>审核列表</h3>
        </div>
        <div class="table">
            <table>
                <tr>
                    <th>订单编号</th>
                    <th>审核状态</th>
                    <th>创建人</th>
                    <th>创建时间</th>
                    <th>更新时间</th>
                    <th width="140">操作</th>
                </tr>
                <tr v-if="total_count == -1">
                    <td colspan="6" align="center">加载中……</td>
                </tr>
                <tr v-if="total_count == 0">
                    <td colspan="6" align="center">暂无记录！</td>
                </tr>
                <tr v-else v-for=" (item, i) in items" :key="i">
                    <td>{{ item.oid }}</td>
                    <td v-html="getStatus(item.status)"></td>
                    <td>{{ item.member_name }}({{ item.member_username }})</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.updated_at }}</td>
                    <td>
                        <span class="btn btn-light btn-s" @click="handleItemEdit(item.oid)">编辑</span> <span
                            class="btn btn-light btn-s" @click="handleItemDelete(item)">删除</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="page-info">
            <div class="operation" v-if="orderExport && total_count">
                <button class="btn btn-light btn-s" @click="handleDownload"
                    v-if="userInfo.role_permissions.includes('ADMIN')">下载数据</button>
            </div>
            <Pagination :total_count="total_count" :current_page="query.page" :page_size="query.page_size"
                @handlePage="handlePage" @handlePageSize="handlePageSize">
            </Pagination>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination
    },
    props: {
        msg: String
    },
    mounted() {
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList()
    },
    data() {
        return {
            query: {},
            total_count: 0,
            items: []
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                page: 1,
                page_size: 10
            }
            return query
        },
        onClear() {
            this.$router.push({ query: {} })
            this.query = this.getDefaultQuery()
            this.fetchList()
        },
        onSearch() {
            this.query.page = 1
            this.$router.push({ query: this.query })
            this.fetchList()
        },
        fetchList() {
            this.startLoading()
            this.$api.post('order/modifies', this.query).then(res => {
                if (res.data.success) {
                    let items = []
                    for (let item of res.data.data.items) {
                        item.data = JSON.parse(decodeURIComponent(item.data))
                        items.push(item)
                    }
                    this.items = items
                    this.total_count = res.data.data.total_count
                    this.query.page = res.data.data.current_page
                    this.query.page_size = res.data.data.page_size
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleItemEdit(oid) {
            this.$router.push('/orders/' + oid + '/modify/bzjp')
        },
        handlePage(page) {
            this.query.page = page
            this.$router.push({ query: this.query })
            this.fetchList()
        },
        handlePageSize(pageSize) {
            this.query.page_size = pageSize
            this.$router.push({ query: { ...this.query, ...{ page: 1 } } })
            this.fetchList()
        },
        getStatus(status) {
            if (status == 1) {
                return '<span class="badge text-bg-success">通过</span>'
            } else if (status == 2) {
                return '<span class="badge text-bg-warning">拒绝</span>'
            }
            return '<span class="badge text-bg-light">待审核</span>'
        },
        handleItemDelete(item) {
            if (confirm('确定要删除【' + item.oid + '】修改的结果吗？')) {
                this.$api.post('order/modify_delete', { id: item.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>