<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>后缀编辑</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AdTagForm v-model="data" @handleSubmit="handleSubmit"></AdTagForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import AdTagForm from '../../components/AdTagForm.vue'

export default {
    name: 'WeixinGroupEdit',
    components: {
        AdTagForm
    },
    data() {
        return {
            isReady: false,
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('ad_tag/detail', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                    this.isReady = true
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        toList() {
            this.$router.push('/ad_tags?ad_account_id=' + this.$route.query.ad_account_id)
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('ad_tag/update', data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>