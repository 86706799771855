<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>{{ $route.params.domain }} 添加解析</h3>
            <div class="actions">
                <span class="btn btn-light" @click="toList">返回解析列表</span>
            </div>
        </div>
        <DomainDnsForm v-model="data" @handleSubmit="handleSubmit"></DomainDnsForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import DomainDnsForm from '../../components/DomainDnsForm.vue'

export default {
    name: 'WeixinGroupNew',
    components: {
        DomainDnsForm
    },
    data() {
        return {
            data: {
                domain: this.$route.params.domain,
                RR: ''
            }
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        toList() {
            this.$router.push('../dns')
        },
        handleSubmit(data) {
            let regex = /[a-z][a-z0-9]{3,}/g
            if (!regex.test(data.RR)) {
                alert('请填写4~8个字符，并以字母开头的前缀!');
                return
            }
            this.startLoading()
            this.$api.post('domain/dns_create', data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                    this.toList()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>