<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">前缀:</span>
                <input type="text" v-model.trim="data.RR" class="form-control col">
                <span class="form-label">IP:</span>
                <input type="text" v-model.trim="data.value" class="form-control col">
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
export default {
    name: 'DomainDnsForm',
    props: {
        value: Object
    },
    data() {
        return {
            data: this.value
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>