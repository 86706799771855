<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>创建订单</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <form @submit.prevent="handleSubmit" class="form">
            <OrderForm ref="form" v-model="data" :is-new="true"></OrderForm>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex'

import OrderForm from '../../components/OrderForm.vue'

export default {
    name: 'OrderShow',
    components: {
        OrderForm
    },
    data() {
        return {
            orderModify: process.env.VUE_APP_ORDER_MODIFY === 'true',
            orderLog: process.env.VUE_APP_ORDER_LOG === 'true',
            data: {
                type: null,
                data: {
                    username: null,
                    gender: null,
                    lDate: ''
                }
            },
            logs: [],
            total_count: -1,
            page: 1,
            page_size: 10
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        handleSubmit() {
            this.startLoading()
            let localData = { ...this.data }
            localData.data = this.$refs.form.getEncodeData()
            this.$api.post('order/create', localData).then(res => {
                alert(res.data.message)
                if (res.data.success) {
                    this.fetchLogs()
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleModify() {
            this.$router.push('modify/bzjp')
        }
    }
}
</script>