<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">微信组名称:</span>
                <input type="text" v-model="data.name" class="form-control col">
                <span class="form-label">微信客服:</span>
                <Selection :items="weixinMps" placeholder="请选择微信客服" v-model="data.weixin_kf_id" style="col">
                </Selection>
            </div>
            <div class="form-item" v-if="weixinGroupLeader">
                <span class="form-label">微信组成员:</span>
                <ul class="children col">
                    <li v-for="(item, index) in members" :key="index" class="form-check">
                        <label><input type="checkbox" v-model="data.member_ids" :value="index">{{ item }}</label>
                    </li>
                </ul>
                <span class="form-label">售卖价格:</span>
                <input type="text" v-model="data.moneys" class="form-control col">
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'WeixinGroupForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            weixinGroupLeader: process.env.VUE_APP_WEIXIN_GROUP_LEADER === 'true',
            members: [],
            weixinMps: [],
            data: this.value
        }
    },
    mounted() {
        this.fetchMembers()
        this.fetchWeixinMps()
    },
    methods: {
        fetchMembers() {
            this.$api.get('members', { params: { permissions: 'WEIXIN_LEADER;WEIXIN_MEMBER' } }).then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchWeixinMps() {
            this.$api.get('weixin_kfs', this.query).then(res => {
                if (res.data.success) {
                    this.weixinMps = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>