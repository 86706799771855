<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">账户:</span>
                <Selection v-model="data.ad_account_id" :readonly="true" remote="ad_accounts" style-class="col"></Selection>
            </div>
            <div class="form-item">
                <span class="form-label">折扣率:</span>
                <input type="text" v-model="data.rate" class="form-control col" placeholder="如：0.1表示10%折扣">
                <span class="form-label">媒体产品:</span>
                <Selection
                    :items="{ 'BaiduSearch': '百度搜索', 'BaiduFeed': '百度信息流' }"
                    placeholder="请选择媒体产品" v-model="data.media_product" style-class="col"></Selection>
            </div>
            <div class="form-item">
                <span class="form-label">开始时间:</span>
                <input type="date" v-model="data.start_date" class="form-control col">
                <span class="form-label">结束时间:</span>
                <input type="date" v-model="data.end_date" class="form-control col">
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'AdAccountDiscountForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            data: {
                ad_account_id: this.$route.params.id
            }
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        },
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>