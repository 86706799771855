<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>后缀列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加后缀</span>
                    </div>
                </div>
                <form class="search" @submit.prevent="onSearch">
                    <div class="search-item">
                        <label>推广账户:</label>
                        <Selection placeholder="请选择归属账户" v-model="query.ad_account_id" :readonly="true"
                            remote="ad_accounts"></Selection>
                    </div>
                    <div class="search-item">
                        <label>关键词:</label>
                        <input type="text" class="form-control kw" placeholder="如：域名/后缀" v-model="query.kw">
                    </div>
                    <div class="search-item">
                        <button type="submit" class="btn btn-primary">查询</button>
                        <button type="button" class="btn btn-light" @click="onClear">清空</button>
                    </div>
                </form>
                <div class="table">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="160">域名</th>
                            <th width="100">后缀名</th>
                            <th>推广账户</th>
                            <th width="80">Token</th>
                            <th width="140">创建时间</th>
                            <th width="140">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td>{{ item.hostname }}</td>
                            <td>{{ item.code }}</td>
                            <td>{{ item.ad_account_username }}</td>
                            <td>{{ item.ocpc_token ? '已设置' : '' }}</td>
                            <td>{{ item.created_at }}</td>
                            <td><span class="btn btn-light btn-s"
                                    @click="handleItemEdit(item.id)">编辑</span> <span class="btn btn-light btn-s"
                                    @click="handleItemDelete(item)">删除</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="page" :page_size="page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'
import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination,
        Selection
    },
    props: {
        msg: String
    },
    mounted() {
        this.fetchList()
    },
    data() {
        return {
            weixin_groups: [],
            query: {
                ad_account_id: this.$route.query.ad_account_id,
                kw: ''
            },
            page: 1,
            page_size: 10,
            total_count: 0,
            items: [],
            itemData: null
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        onClear() {
            this.query.kw = ''
            this.fetchList()
        },
        onSearch() {
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('ad_tag/list', { ...this.query, ...{ page: page, page_size: this.page_size } }).then(res => {
                if (res.data.success) {
                    this.items = res.data.data.items
                    this.total_count = res.data.data.total_count
                    this.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.fetchList(page)
        },
        handleNew() {
            this.$router.push({
                path: '/ad_tags/new',
                query: { ad_account_id: this.$route.query.ad_account_id }
            })
        },
        handleItemEdit(id) {
            this.$router.push('/ad_tags/' + id + '/edit')
        },
        handleItemDelete(item) {
            if (confirm('确定要删除用户【' + item.hostname + '-' + item.code + '】吗？')) {
                this.$api.post('ad_tag/delete', { id: item.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    }
}
</script>
