<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>添加账户</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AdAccountForm v-model="data" @handleSubmit="handleSubmit"></AdAccountForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import AdAccountForm from '../../components/AdAccountForm.vue'

export default {
    name: 'WeixinGroupNew',
    components: {
        AdAccountForm
    },
    data() {
        return {
            data: {
                balance_ad_account_ids: [''],
                weixin_group_ids: []
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        toList() {
            this.$router.push('/ad_accounts')
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('ad_account/create', data).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                    this.toList()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>